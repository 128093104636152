import React from 'react'
import ReactDOM from 'react-dom/client'
// @ts-ignore
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import 'unno-comutils/style/core.css'
//import './style/core.css'
import './style/app.scss'

import { unregister } from './service/worker'

import reducer from './service/reducer'
import App from './app'

const store = createStore(reducer)

const root = document.getElementById('root')
if (root) ReactDOM.createRoot(root).render(<Provider store={store}><App/></Provider>)
//ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'))

unregister()
