import React, { useEffect, useMemo, useState } from 'react'
import L from '@loadable/component'

import { BrowserRouter } from 'react-router-dom'

import { checkLogin, logout } from 'unno-comutils/Connect'
import { ToastContainer } from 'unno-comutils/Dialog'

import Wait from 'unno-comutils/Wait'
import MainLayout from './layout/main'
import { useDispatch, useSelector } from 'react-redux'

export const fallback = <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img src={'https://files.un-no.net/static/loading.svg'} alt={'img-loading'} style={{ width: '100px' }}/></div>

const Routing = L(() => import(/* webpackChunkName: "routing" */'./service/router'), { fallback })

export const GMAP_LOAD: any = { libraries: ['geometry', 'places', 'visualization'], key: process.env.REACT_APP_GMAP, language: 'th' }
export const GMAP_MODE_HYBRID = { mapTypeControl: true, mapTypeId: 'hybrid', styles: [{ featureType: 'poi.business', elementType: 'labels', stylers: [{ visibility: 'on' }] }] }

export const GMAP_DEFAULT_PROP = { bootstrapURLKeys: GMAP_LOAD, options: GMAP_MODE_HYBRID, defaultCenter: { lat: 13, lng: 100 }, defaultZoom: 7 }

export default function App (props: any) {
    const [wait, setWait] = useState(true)
    const user = useSelector((s: any) => s.user)
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    dispatch({ type: 'USER_LOGIN', user: d.user })
                }
                else logout()
                setWait(false)
            })
        }, 200)
    }, [])

    const site = useMemo(() => {
        if (document.location.host.startsWith('local') || document.location.host.startsWith('n.')) return 'north'
        return 'main'
    }, [])

    if (wait) return <Wait/>

    return <>
        <div className={'layout site_' + site}>
            <BrowserRouter>
                {!!user ? <MainLayout children={<Routing/>}/> : <Routing/>}
            </BrowserRouter>
        </div>
        <ToastContainer/>
    </>
}
