const old = true

const colors = ['red', 'pink', 'purple', 'blue', 'sky', 'green', 'orange', 'brown', 'grey', 'grey-blue']
const cc = colors.length
let cx = cc - 1

const cmEmp = (key: string, name: string, group = 1011, old?: boolean) => ({ sys: 10, group, key, name, url: '/employee/' + key, old })
const MenuEmployees = [
    { ...cmEmp('data', 'บุคลากร'), icon: 'users', system: ['main', 'north'], role: 'employee.data' },
    { sys: 10, group: 1011, key: 'time', name: 'เวลาการทำงาน', url: '/employee/time', icon: 'watch-calculator', system: ['main'], role: 'employee.worktime' },
    { sys: 10, group: 1011, key: 'position', name: 'ตำแหน่งงาน', url: '/employee/position', icon: 'sparkles', system: ['main', 'north'], role: 'employee.data' },
    { sys: 10, group: 1011, key: 'kpi', name: 'KPI', url: '/employee/kpi', icon: 'star-half-alt', system: ['main'], role: 'employee.kpi' },
    // { sys: 10, group: 1011, key: 'setting', name: 'แก้ไขข้อมูลบุคลากร', url: '/employee/setting', icon: 'user-edit', system: ['main'], role: 'employee.data' },
    { old, sys: 10, group: 1011, key: 'training', name: 'การฝึกอบรม', url: '/employee/training', icon: 'graduation-cap', system: ['main'], role: 'employee.training' },
    { sys: 10, group: 1011, key: 'health', name: 'การตรวจสุขภาพ', url: '/employee/health', icon: 'heartbeat', system: ['main'], role: 'employee.checkup' },
    //{ sys: 10, group: 1011, key: 'checkup', name: 'การตรวจสุขภาพ', url: '/employee/checkup', icon: 'heartbeat', system: ['main'], role: 'employee.checkup' },
    { sys: 10, group: 1011, key: 'absent', name: 'การลาหยุด', url: '/employee/absent', icon: 'calendar-times', system: ['main'], role: 'employee.absent' },
    { sys: 10, group: 1011, key: 'amerce', name: 'การลงโทษ', url: '/employee/amerce', icon: 'fire-smoke', system: ['main'], role: 'employee.amerce' },
    { sys: 10, group: 1011, key: 'testing', name: 'การทดสอบ', url: '/employee/testing', icon: 'ballot-check', system: ['main'], role: 'employee.testing' },
    { sys: 10, group: 1011, key: 'appraise', name: 'การประเมินผล', url: '/employee/appraise', icon: 'clipboard-list-check', system: ['main'], role: 'employee.appraise' },
    //{ sys: 10, group: 1011, key: 'setting', name: 'ตั้งค่าระบบบุคลากร', url: '/employee/setting', icon: 'cog', system: ['main'], role: '__admin' },

    { old, sys: 10, group: 1311, key: 'revenue', name: 'การจ่ายค่าจ้าง', url: '/employee/revenue', icon: 'money-bill-wave', system: ['main'], role: 'employee.revenue' },
    { ...cmEmp('revenue.driver', 'ค่าจ้าง พขร.', 1311, old), icon: 'money-check-edit-alt', system: ['main'], role: 'employee.revenue.driver' },
    { old, sys: 10, group: 1311, key: 'warrant', name: 'เงินประกัน', url: '/employee/warrant', icon: 'backpack', system: ['main'], role: 'employee.warrant' },
]

const MenuLogistics = [
    { sys: 20, group: 2011, key: 'plan', name: 'จัดแผนการขนส่ง', url: '/logistic/plan', icon: 'solar-system', system: ['main'], role: 'logistic.transport' },

    { sys: 20, group: 2011, key: 'invoice_front', name: 'คีย์หน้างาน', url: '/logistic/invoice_front', icon: 'tree-palm', system: ['main', 'north'], role: 'logistic.transport' },
    { sys: 20, group: 2011, key: 'invoice', name: 'ข้อมูลขนส่ง', url: '/logistic/invoice', icon: 'receipt', system: ['main', 'north'], role: 'logistic.transport' },
    { sys: 20, group: 2011, key: 'invoice_route', name: 'จัดเที่ยววิ่ง', url: '/logistic/invoice_route', icon: 'shipping-fast', system: ['main', 'north'], role: 'logistic.transport' },
    { sys: 20, group: 2011, key: 'invoice_detail', name: 'บันทึกรายละเอียด', url: '/logistic/invoice_detail', icon: 'scanner-keyboard', system: ['main', 'north'], role: 'logistic.transport' },

    { sys: 20, group: 2012, key: 'fuel', name: 'เติมน้ำมันเชื่อเพลิง', url: '/logistic/fuel', icon: 'gas-pump', system: ['main', 'north'], role: 'logistic.fuel' },
    { sys: 20, group: 2020, key: 'trailer', name: 'ข้อมูลรถขนส่ง', url: '/logistic/trailer', icon: 'truck-moving', system: ['main', 'north'], role: 'logistic.trailer' },
    // { dev: true, sys: 20, group: 2012, key: 'trailer_setting', name: 'แก้ไขข้อมูลรถขนส่ง', url: '/logistic/trailer_setting', icon: 'comment-alt-edit', system: ['main'], role: 'logistic.trailer' },
    { sys: 20, group: 2020, key: 'insure', name: 'การต่ออายุต่างๆ', url: '/logistic/trailer_renew', icon: 'shield-alt', system: ['main'], role: 'logistic.insure' },
    //{ old, sys: 20, group: 2051, key: 'config', name: 'ตั้งค่าพื้นฐาน', url: '/logistic/config', icon: 'cog', system: ['main'], role: 'logistic.config' },
    { sys: 20, group: 2020, key: 'customer', name: 'ข้อมูลลูกค้า', url: '/logistic/customer', icon: 'users-crown', system: ['main', 'north'], role: 'logistic.customer' },

    { sys: 20, group: 2013, key: 'node', name: 'ข้อมูลจุดส่ง', url: '/logistic/node', icon: 'map-pin', system: ['main', 'north'], role: 'logistic.node' },
    { sys: 20, group: 2013, key: 'node_route', name: 'จุดเชื่อมต่อ', url: '/logistic/node_route', icon: 'chart-network', system: ['main', 'north'], role: 'logistic.node' },

    { sys: 20, group: 2013, key: 'poi', name: 'ข้อมูล POI', url: '/logistic/poi', icon: 'map-marker-alt', system: ['main', 'north'], role: '__admin' },
]

const cmFin = (key: string, name: string, group = 3011, old?: boolean) => ({ sys: 30, group, key, name, url: '/finance/' + key, old })
const MenuFinances = [
    { sys: 30, group: 3011, key: 'withdraw', name: 'จัดกลุ่มตั้งเบิก', url: '/finance/withdraw_group', icon: 'envelope-open-dollar', system: ['main', 'north'], role: 'finance.withdraw' },
    { sys: 30, group: 3011, key: 'disburse', name: 'ตั้งเบิกผู้ว่าจ้าง', url: '/finance/withdraw', icon: 'comments-alt-dollar', system: ['main', 'north'], role: 'finance.disburse' },
    { ...cmFin('cashflow.maintain', 'รับ-รายจ่าย อื่นๆ', 3011, old), icon: 'badge-dollar', system: ['main'], role: 'finance.cashflow.maintain' },
    { old, sys: 30, group: 3011, key: 'cashbook', name: 'รายรับ-รายจ่าย อื่นๆ', url: '/finance/cashbook', icon: 'file-invoice-dollar', system: ['main'], role: 'finance.cashbook' },
    { old, sys: 30, group: 3011, key: 'gps.cost', name: 'ค่าใช้จ่าย GPS', url: '/finance/gps.cost', icon: 'globe-asia', system: ['main'], role: 'finance.gps.cost' },
    { old, sys: 30, group: 3211, key: 'fuel.truncate', name: 'ตัดยอดน้ำมัน', url: '/finance/fuel.truncate', icon: 'book-spells', system: ['main'], role: 'finance.fuel.truncate' },
    { old, sys: 30, group: 3211, key: 'seaborne', name: 'ระบบตั๋วเรือ', url: '/finance/seaborne', icon: 'ship', system: ['main'], role: 'finance.seaborne' },
    { old, sys: 30, group: 3211, key: 'seaborne2', name: 'เรือเกาะเต่า', url: '/finance/seaborne2', icon: 'anchor', system: ['main'], role: 'finance.seaborne2' },
    { ...cmFin('report.hire', 'รับ-รายจ่าย อื่นๆ', 3911, old), icon: 'sack-dollar', system: ['main'], role: 'finance.report.hire' },
    { sys: 30, group: 3011, key: 'book', name: 'บันทึกรับจ่ายภายนอก', url: '/finance/book', icon: 'coins', system: ['main'], role: '__admin' },
]

const doc1 = { sys: 40, group: 4011 }
const daUrl = '/document/appraise/'
const MenuDocuments = [
    { ...doc1, key: 'plan', name: 'วางแผนการติดตาม', url: '/document/plan', icon: 'radar', system: ['main'], role: 'document.plan' },
    { ...doc1, key: 'meeting', name: 'วาระการประชุม', url: '/document/meeting', icon: 'chess', system: ['main'], role: 'document.log' },
    { ...doc1, key: 'file', name: 'ทะเบียนเอกสาร', url: '/document/entry', icon: 'books', system: ['main'], role: 'document.file' },
    { ...doc1, key: 'appraise.cutomer', name: 'ความพึงพอใจลูกค้า', url: daUrl + 'customer', icon: 'ballot-check', system: ['main'], role: 'document.appraise' },
    { ...doc1, key: 'appraise.warehouse', name: 'ความพึงพอใจคลัง', url: daUrl + 'warehouse', icon: 'clipboard-list-check', system: ['main'], role: 'document.appraise' },
    //{ old, ...doc1, key: 'log', name: 'บันทึกการใช้งานระบบ', url: '/document/log', icon: 'server', system: ['main'], role: 'document.log' },
    { ...doc1, key: 'notice', name: 'ประกาศ', url: '/document/notice', icon: 'file-medical-alt', system: ['main'], role: 'document.notice' },
    { ...doc1, key: 'manual', name: 'คู่มือ', url: '/document/manual', icon: 'book', system: ['main'], role: 'document.notice' },
    //{ ...doc1, key: 'driver_line', name: 'แจ้งเตือน พขร. ทางไลน์', url: '/document/driver_line', icon: 'bell-on', system: ['main'], role: 'document.notice' },
    { ...doc1, key: 'ivms', name: 'IVMS', url: '/document/ivms', icon: 'camcorder', system: ['main'], role: 'document.notice' },
    { ...doc1, key: 'pump', name: 'Pump', url: '/document/pump', icon: 'gas-pump', system: ['main'], role: 'document.notice' },
]

const sto2 = { sys: 50, group: 5013 }
const MenuStores = [
    { sys: 50, group: 5011, key: 'store.purchase', name: 'สั่งซื้อ', url: '/store/purchase', icon: 'shopping-basket', system: ['main'] },
    { sys: 50, group: 5011, key: 'store.use', name: 'เบิกพัสดุจากคลัง', url: '/store/use', icon: 'conveyor-belt', system: ['main'] },
    { sys: 50, group: 5012, key: 'store.product', name: 'พัสดุ/วัสดุ', url: '/store/product', icon: 'boxes-alt', system: ['main'], role: 'store.data' },
    { sys: 50, group: 5012, key: 'store.shop', name: 'ร้านค้า', url: '/store/shop', icon: 'store', system: ['main'] },
    //{ old, sys: 50, group: 5011, key: 'config', name: 'ตั้งค่าพื้นฐาน', url: '/store/config', icon: 'cog', system: ['main'], role: 'store.config' },
    { old, ...sto2, key: 'logistic.oilprice', name: 'ราคาน้ำมันต่อวัน', url: '/store/logistic.oilprice', icon: 'gas-pump', system: ['main'], role: 'store.logistic.oilprice' },
    { old, ...sto2, key: 'purchase.group', name: 'จัดกลุ่มบันทึกใบเสร็จ', url: '/store/purchase.group', icon: 'receipt', system: ['main'], role: 'store.purchase.group' },
]

const MenuMaintains = [
    { old, sys: 60, group: 6011, key: 'equipment', name: 'อุปกรณ์เสริม', url: '/maintain/equipment', icon: 'tablet-rugged', system: ['main'], role: 'maintain.equipment' },
    { sys: 60, group: 6011, key: 'maintain.purchase', name: 'ใบเสร็จซ่อม', url: '/maintain/purchase', icon: 'file-invoice-dollar', system: ['main'], role: 'maintain.fix' },
    { sys: 60, group: 6011, key: 'maintain', name: 'ซ่อมบำรุง', url: '/maintain/maintenance', icon: 'tools', system: ['main'], role: 'maintain.fix' },
    { sys: 60, group: 6011, key: 'maintain.plan', name: 'วางแผนซ่อมบำรุง', url: '/maintain/plan', icon: 'toolbox', system: ['main'], role: 'maintain.fix.plan' },
    //{old, sys: 99, group: 6011, key: 'maintain', name: 'แจ้งซ่อมบำรุง', url: '/maintain/maintain', icon: 'filemedicalalt'},
    { old, sys: 60, group: 6011, key: 'maintain.tire', name: 'ระบบจัดการยาง', url: '/maintain/tire', icon: 'tire', system: ['main'], role: 'maintain.tire' },
    //{old, sys: 99, group: 6011, key: 'maintain.plan', name: 'วางแผนการซ่อมบำรุง', url: '/maintain/maintain.plan', icon: 'filemedicalalt'},
    //{ old, sys: 60, group: 6011, key: 'tire.req', name: 'ใบแจ้งขอเปลี่ยนยาง', url: '/maintain/tire.req', icon: 'tire-flat', system: ['main'], role: 'maintain.tire.req' },
    //{old, sys: 99, group: 6011, key: 'maintain.checkup', name: 'ตรวจรถประจำวัน', url: '/maintain/maintain.checkup', icon: 'filemedicalalt'},
    { old, sys: 60, group: 6011, key: 'maintain.trailer', name: 'บำรุงรักษารถ', url: '/maintain/maintain', icon: 'screwdriver', system: ['main'], role: 'maintain.maintain' },
    { sys: 60, group: 6011, key: 'maintain.trouble', name: 'แจ้งซ่อม', url: '/maintain/request', icon: 'ballot-check', system: ['main'], role: 'maintain.trailer.trouble' },
    { sys: 60, group: 6011, key: 'maintain.trailer_checkup', name: 'การตรวจรถ', url: '/maintain/checkup', icon: 'star', system: ['main'], role: 'maintain.checkup' },
]

const MenuSafetys = [
    { sys: 70, group: 7011, key: 'accident', name: 'บันทึกอุบัติเหตุ', url: '/safety/accident', icon: 'car-crash', system: ['main'], role: 'safety.accident' },
    { sys: 70, group: 7011, key: 'appraise_risk', name: 'ประเมินความเสี่ยง', url: '/safety/appraise_risk', icon: 'car-tilt', system: ['main'], role: 'safety.appraise.risk' },
    { sys: 70, group: 7011, key: 'problem', name: 'ปัญหาการขนส่ง', url: '/safety/problem', icon: 'exclamation-triangle', system: ['main'], role: 'safety.problem' },
    { old, sys: 70, group: 7011, key: 'problem.gps', name: 'บันทึกปัญหาโดย GPS', url: '/safety/problem.gps', icon: 'map-pin', system: ['main'], role: 'safety.problem.gps' },
    { sys: 70, group: 7011, key: 'tracking', name: 'ติดตามการทำงาน', url: '/safety/tracking', icon: 'analytics', system: ['main'], role: 'safety.tracking' },

    { sys: 70, group: 7011, key: 'routecard', name: 'Route Card', url: '/safety/routecard', icon: 'credit-card-front', system: ['main', 'north'], role: 'safety.routecard' },
    { sys: 70, group: 7011, key: 'riskpoint', name: 'จุดเสี่ยง', url: '/safety/riskpoint', icon: 'snowboarding', system: ['main', 'north'], role: 'safety.riskpoint' },
    { sys: 70, group: 7011, key: 'pttimport', name: 'นำเข้าข้อมูล ปตท.', url: '/safety/pttimport', icon: 'cloud-upload', system: ['main'], role: 'safety.pttimport' },
    { old, sys: 70, group: 7011, key: 'random', name: 'สุ่มตรวจ', url: '/safety/random', icon: 'scanner', system: ['main'], role: 'safety.random' },
]

const job1 = { sys: 80, group: 8000 }
const MenuJobbers = [
    { ...job1, key: 'sms', name: 'SMS', url: '/jobber/sms', icon: 'sms', system: ['main'], role: 'jobber.sms' },
    { ...job1, key: 'price', name: 'ราคาน้ำมัน', url: '/jobber/price', icon: 'tags', system: ['main'], role: 'jobber.price' },
    { ...job1, key: 'view1', name: 'กราฟน้ำมัน', url: '/jobber/ext/view1', icon: 'chart-line', system: ['main'], role: 'jobber.view1' },
    { ...job1, key: 'view2', name: 'ราคาหน้าปั้ม', url: 'https://www.pttor.com/oilprice-province.aspx', ext: 1, icon: 'list-alt', system: ['main'], role: 'jobber.view2' },
]

export const Menus = [
    {
        key: 'core', name: 'หลัก', icon: 'tools', color: colors[cx++ % cc],
        items: [
            { key: 'home', name: 'หน้าแรก', url: '/home', icon: 'home', system: ['main', 'north'] },
            { key: 'meta', name: 'ข้อมูลพื้นฐาน', url: '/meta', icon: 'snooze', system: ['main', 'north'], role: '__admin' },
            //{ key: 'dev', name: 'ทดสอบ', url: '/test', icon: 'vial', system: ['main', 'north'], role: '__admin' },
            { key: 'admin', name: 'ACP', url: '/admin', icon: 'users-cog', system: ['main', 'north'], role: '__admin' },
        ]
    },
    { id: 10, key: 'employee', name: 'ระบบบุคลากร', color: 'red', role: '_employee', items: MenuEmployees },
    { id: 20, key: 'logistic', name: 'ระบบขนส่ง', color: 'pink', role: '_logistic', items: MenuLogistics },
    { id: 30, key: 'finance', name: 'ระบบบัญชี การเงิน', color: 'purple', role: '_finance', items: MenuFinances },
    { id: 40, key: 'document', name: 'ระบบงานเอกสาร', color: 'blue', role: '_document', items: MenuDocuments },
    { id: 50, key: 'store', name: 'ระบบพัสดุ จัดซื้อ', color: 'sky', role: '_store', items: MenuStores },
    { id: 60, key: 'maintain', name: 'ระบบซ่อมบำรุง', color: 'green', role: '_maintain', items: MenuMaintains },
    { id: 70, key: 'safety', name: 'ระบบความปลอดภัย', color: 'orange', role: '_safety', items: MenuSafetys },
    { id: 80, key: 'jobber', name: 'Jobber', color: 'brown', role: '_jobber', items: MenuJobbers }
]
