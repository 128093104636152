import React, { useEffect, useMemo, useState } from 'react'

import { clsNames, isDev as dev } from 'unno-comutils'

import { Menus } from '../service/menu'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import Dropdown from 'unno-comutils/Dropdown'
import { Icon } from 'unno-comutils/ui'

let isDev = dev

export const filterMenu = (m: any, user: any) => {
    return (!m.role || user.roles.indexOf(m.role) >= 0) && (!m.system || m.system.indexOf(user.system) >= 0)
}

export const getFavMenu = () => (localStorage.getItem('menu_fav') || 'core|home,core|support').split(',').filter(Boolean) || []

export const matchMenu = (menu: any, cPath: string) => (menu.urlMatch && cPath.startsWith(menu.urlMatch + '/')) || cPath.startsWith(menu.url + '/')

export const itemUrl = (m: any) => !m.old ? m.url : process.env.REACT_APP_OLDURL + m.url

export const memoMenus = (user: any) => {
    return Menus.filter(m => filterMenu(m, user))
        .map((sm: any) => {
            if (!sm.items.some((m: any) => m.sys === sm.id && filterMenu(m, user))) return null
            let saveGroup = 0

            return {
                key: sm.key,
                name: sm.name,
                color: sm.color,
                items: sm.items.filter((m: any) => m.sys === sm.id && filterMenu(m, user))
                    .map((m2: any) => {
                        const isOld = !!m2.old || (!!m2.dev && !isDev)
                        const next = !!saveGroup && saveGroup !== m2.group
                        saveGroup = m2.group
                        return { key: m2.key, next, icon: m2.icon, url: isOld ? process.env.REACT_APP_OLDURL + m2.url : m2.url, name: m2.old ? `${m2.name}*` : m2.name }
                    })
            }
        }).filter(Boolean)
}

export function MenuMain (props: any) {
    const location = useLocation()
    const user = useSelector((s: any) => s.user)

    const [dmenu, setDMenu] = useState(false)
    const [fav, setFav] = useState(getFavMenu())

    const onClickFav = (page: any, e: any) => {
        e.stopPropagation()
        toggleFavMenu(page)
    }

    const toggleFavMenu = (name: string) => {
        const saveMenu = getFavMenu()
        if (saveMenu.indexOf(name) >= 0) {
            localStorage.setItem('menu_fav', saveMenu.filter((m: any) => m !== name).join(','))
        }
        else {
            saveMenu.push(name)
            localStorage.setItem('menu_fav', saveMenu.join(','))
        }
        const gfav = getFavMenu()
        setFav(gfav)
        props.onUpdate(gfav)
    }

    useEffect(() => {
        setDMenu(false)
    }, [location.pathname])

    const menus = useMemo(() => memoMenus(user), [user])

    return <Dropdown show={dmenu} onToggle={(d: any) => setDMenu(d)} className={'menumain-dropdown un-scroll'}
                     button={<Icon name={'bars'} solid className="menumain-btn"/>}>
        {menus.map((m: any) => <MenuMainGroup key={'x_' + m.name} menu={m} fav={fav} onClickFav={onClickFav}/>)}
    </Dropdown>

}

export function MenuMainGroup (props: any) {
    const { menu, fav, onClickFav } = props

    if (menu.key === 'core') {
        return <div className="menumain-group-core">
            {menu.items.map((m1: any) => <MenuMainItem key={'c_' + m1.key} group={menu.key} menu={m1} fav={fav} onClickFav={onClickFav}/>)}
        </div>
    }

    return <div className="menumain-group">
        <div className="menumain-group_header"><span>{menu.name}</span></div>
        <div className={'menumain-group_item'}>
            <div className="menumain-items">{menu.items.map((m2: any) => <MenuMainItem key={'m_' + m2.key} group={menu.key} next={m2.next} menu={m2} fav={fav} onClickFav={onClickFav}/>)}</div>
        </div>
    </div>
}

export function MenuMainItem (props: any) {
    const { group, next, menu, fav, onClickFav } = props

    const location = useLocation()
    const history = useHistory()

    const onClickItem = (m: any, e: any) => {
        if (!e.ctrlKey && !m.old) {
            e.preventDefault()
            history.push(m.url)
        }
    }

    const cPath = useMemo(() => location.pathname + '/', [location])
    const fk = [group, menu.key].join('|')

    return <>
        {next && <div className={'menumain-item-next'}/>}
        <div className={clsNames('menumain-item', matchMenu(menu, cPath) && '-active', fav.some((n: any) => n === fk) && '-fav_active')}>
            <div className="_box">
                <a href={itemUrl(menu)} className="_link" onClick={e => onClickItem(menu, e)}>
                    <Icon name={menu.icon} className="_icon" solid/>
                    <span className="_text">{menu.name} {menu.old && '*'}</span>
                </a>
                <Icon name={'star'} className={'_icon-fav'} onClick={e => onClickFav(fk, e)}/>
            </div>
        </div>
    </>
}
