import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { clsNames, dConfirm, tSuccess, version } from 'unno-comutils'
import { logout } from 'unno-comutils/Connect'
import { Icon } from 'unno-comutils/ui'
import Tooltip from 'unno-comutils/Tooltip'
import { getFavMenu, itemUrl, matchMenu, memoMenus, MenuMain } from './menu'

export default function MainLayout (props: any) {
    const [miniSideMenu, setMiniSideMenu] = useState(false)

    const [fav, setFav] = useState(getFavMenu())

    const dispatch = useDispatch()

    const user = useSelector((s: any) => s.user)
    const location = useLocation()
    const history = useHistory()

    // ----- ACTION

    const userLogout = () => {
        dConfirm('Logout ?').then(() => {
            logout().then(() => {
                tSuccess('ออกจากระบบแล้ว')
                dispatch({ type: 'USER_LOGOUT' })
            })
        })
    }

    const onMiniSideMenu = () => {
        const mini = !miniSideMenu
        setMiniSideMenu(mini)
        localStorage.setItem('SIDEMENU_MINI', mini ? '1' : '')
    }

    // ----- EVENT

    const onClickItem = (m: any, e: any) => {
        if (!e.ctrlKey && !m.old) {
            e.preventDefault()
            history.push(m.url)
        }
    }

    // ----- MEMO

    useEffect(() => {
        setMiniSideMenu(localStorage.getItem('SIDEMENU_MINI') === '1')
    }, [])

    const menus = useMemo(() => {
        const menus: any = []
        memoMenus(user).forEach((m1: any) => {
            m1.items.forEach((m2: any) => {
                if (fav.some((n: any) => n === m1.key + '|' + m2.key))
                    menus.push({ ...m2, color: m1.color })
            })
        })
        return menus
    }, [user, fav])

    const cPath = useMemo(() => location.pathname + '/', [location])

    // ----- RENDER

    return <>
        <div className={clsNames('layout-sidemenu', props.grow && ' -grow', miniSideMenu && ' -mini')}>
            <div className="layout-mainmenu">
                <MenuMain onUpdate={setFav}/>
                <Icon name={'power-off'} className={'white ml-auto _no-mini-show'} button lg onClick={userLogout}/>
            </div>
            <div className="layout-menuifo">
                <span className={'_version'}>v {process.env.REACT_APP_VERSION} ~ {version}</span>
                <span className={'_user'}>{user && user.name}</span>
            </div>

            <div className="layout-menu">
                <div className="_protect">
                    <div className="page-content">
                        {menus.map((m: any) => {
                            const cls = clsNames('layout-menu-item', '_link', '-' + 'grey-blue', matchMenu(m, cPath) && '-active')
                            const item = <a key={'m_' + m.name} href={itemUrl(m)} className={cls} onClick={e => onClickItem(m, e)}>
                                <Icon name={m.icon} className={clsNames('_icon', m.color)}/>
                                <span className="_text">{m.name} {m.old && '*'}</span>
                            </a>
                            return miniSideMenu ? <Tooltip key={'m_' + m.name} title={m.name} position={'r'}>{item}</Tooltip> : item
                        })}
                    </div>
                    <div className="btn-coll mb-3"><Icon button name={'chevron-double-' + (miniSideMenu ? 'right' : 'left')} onClick={onMiniSideMenu}/></div>
                </div>
            </div>
        </div>
        <div className="layout-content">
            <div className={'_protect'}>
                {props.children}
            </div>
        </div>
    </>
}
