import { User } from 'unno-comutils/utils'

const reducer = (s = { appWait: true, login: false, user: null }, a: any) => {
    if (a.type === 'USER_LOGIN') return { ...s, login: true, user: new User(a.user) }
    if (a.type === 'USER_LOGOUT') return { ...s, login: false, user: null }
    if (a.type === 'UPDATE_STATS') return { ...s, ...a.data }
    return s
}

export default reducer
